import React, { useEffect, useState } from 'react';
import '../css/home.css'
import { Link } from "react-router-dom";
import axios from 'axios';
import Config from "../Config"
import Article from "../components/Atricle"
import Categories from '../components/Categories'
import useScript, { UseScript } from "../components/UseScript"


function NewArive(props) {
    useScript('/instafeed.js')
    let mounted = true;
    const [items, setItems] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (mounted === true) {
            axios.get(Config.ecwidUrl
                + "/products?token=" + Config.ecwidToken
                + "&limit=50"
                + "&category=" + Config.ecwidNewCatId
                + "&enabled=true")

                .then(res => {
                    setLoading(false)
                    console.log(res.data.items)
                    setItems(res.data.items);
                })
                .catch(err => {
                    setError(err.message);
                })
        }

        return () => mounted = false;
    }, []);

    return (<Categories
        showLink={true}
        type='item' showText={false}
        scrollKey="new-arivals"
        loading={loading}
        items={items}></Categories>)
}

function Home(props) {

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');


    useEffect(() => {
        axios.get(Config.strapiUrl + "/blogs?_sort=created_at:DESC&show=1&_limit=1")
            .then(res => {
                setNews(res.data[0]);
                setLoading(false);
            })
            .catch(err => {
                setError(err.message);
                setLoading(false)
            })
    }, []);

    useEffect(() =>{
        (() => {
            const d=document,s=d.createElement("script");s.type="module";
            s.src="https://w.behold.so/widget.js";d.head.append(s);
          })();
    },[])


    return (
        <div className="home">
            <h2>new arrivals</h2>
            <NewArive></NewArive>
            <h2>news <span className="red">+</span> events</h2>
            {!loading &&
                <Article key={news.subject}
                    style={news.style}
                    subject={news.subject}
                    type={news.type}
                    content={news.content}
                    extraContent={news.extra_content}
                    youTube={news.youtube_embed}
                    media={news.media}
                    id={news.id}
                />
            }
            <h2>instagram</h2>
            <div data-behold-id="tM3qDxQh7kjj9ulBHci8"></div>
            <div className="home-bottom">
                <div className="about">
                    <div className="about-text"><Link to="/about">about</Link></div>
                </div>
                <div className="about-nav">
                    <div className="link-container">
                        <Link to="/about#store">store</Link>
                        <Link to="/about#gallery">gallery</Link>
                        <Link to="/about#curator">curator</Link>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Home;
